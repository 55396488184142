<template>
  <div class="gallery-page">
    <MenuNavegacion />
    <div class="gallery-container">
      <h1>Galería</h1>
      <div class="albums">
        <div class="album" v-for="album in uniqueAlbums" :key="album.id">
          <img src="@/assets/galeria.png" alt="Album Preview" @click="openAlbum(album.id)" />
          <div class="album-info">
            <span>{{ album.name }}</span>
            <button class="delete-button" @click="confirmDeleteAlbum(album.id)">
              <img src="@/assets/trash-icon.png" alt="Delete" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'GalleryView',
  components: {
    MenuNavegacion,
  },
  computed: {
    ...mapGetters('gallery', ['albums']),
    uniqueAlbums() {
      // Asegúrate de que no haya duplicados
      return this.albums.filter((album, index, self) =>
        index === self.findIndex((t) => (
          t.id === album.id
        ))
      );
    },
  },
  methods: {
    ...mapActions('gallery', ['fetchAlbums', 'deleteAlbum']),
    openAlbum(albumId) {
      this.$router.push({ name: 'AlbumView', params: { id: albumId } });
    },
    confirmDeleteAlbum(albumId) {
      if (confirm("¿Estás seguro de que quieres borrar este álbum y todas sus fotos?")) {
        this.deleteAlbum(albumId);
      }
    },
    async deleteAlbum(albumId) {
      await this.$store.dispatch('gallery/deleteAlbum', albumId);
      this.$store.commit('gallery/removeAlbum', albumId);
    }
  },
  async mounted() {
    await this.fetchAlbums();
  }
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Evitar el scroll horizontal */
}

.gallery-page {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden; /* Evitar el scroll horizontal */
  display: flex;

  flex-direction: column;
  overflow: hidden;
}

.gallery-container {
  flex: 1;
  padding-top: 70px; /* Margen superior para el menú */
  padding: 20px;
  background-color: white;
  color: #ffffff;
  overflow-y: auto; /* Permitir el scroll vertical */
}

.albums {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.album {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.album img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  opacity: 0.7;
}

.album-info {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #00bfff;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
}

.album-info span {
  font-size: 1.2em;
  font-weight: bold;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.delete-button img {
  width: 20px;
  height: 20px;
}

/* Responsive Design */
@media (min-width: 600px) {
  .albums {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .albums {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>