<template>
  <nav class="menu-navegacion">
    <div class="hamburger-menu" @click="toggleMenu" :class="{ 'is-active': isActive }">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <ul :class="['cont-ul', { 'is-active': isActive }]">
      <li><router-link to="/home">Inicio</router-link></li>
      <li><router-link to="/gallery">Galería</router-link></li>
      <li><router-link to="/calendar">Calendario</router-link></li>
      <li v-if="!isMobile" class="develop">
        <a @click.prevent="toggleSubmenu">Otros</a>
        <ul class="ul-second" :class="{ 'is-active': isSubmenuActive }">
          <li><router-link to="/wishlist">Deseos</router-link></li>
          <li><router-link to="/music">Música</router-link></li>
          <li><router-link to="/map">Mapa</router-link></li>
        </ul>
      </li>
      <li v-if="isMobile"><router-link to="/wishlist">Deseos</router-link></li>
      <li v-if="isMobile"><router-link to="/music">Música</router-link></li>
      <li v-if="isMobile"><router-link to="/map">Mapa</router-link></li>
      <li v-if="isAuthenticated"><router-link to="/admin">Admin Panel</router-link></li>
      <li class="login-button">
        <router-link v-if="!isAuthenticated" to="/login">Login</router-link>
        <button v-if="isAuthenticated" @click="logout">Logout</button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MenuNavegacion',
  data() {
    return {
      isActive: false,
      isSubmenuActive: false,
      isMobile: false
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated'])
  },
  methods: {
    ...mapActions('auth', ['logout']),
    toggleMenu() {
      this.isActive = !this.isActive;
      this.isSubmenuActive = false; // Reset submenu when toggling the main menu
    },
    toggleSubmenu() {
      this.isSubmenuActive = !this.isSubmenuActive;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 900;
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evitar el scroll horizontal */
}

html, body, #app {
  height: 100%;
  width: 100%;
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #24252a;
  padding: 1em;
  position: fixed; /* Fijar el menú en la parte superior */
  top: 0;
  left: 0;
  z-index: 1000; /* Asegurarse de que esté por encima de otros elementos */
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1100; /* Asegurar que esté por encima del contenido */
}

.hamburger-menu span {
  height: 3px;
  width: 25px;
  background: white;
  margin: 4px;
  border-radius: 5px;
  transition: transform 0.3s ease, background 0.3s ease;
}

.hamburger-menu.is-active span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger-menu.is-active span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.is-active span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.cont-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  width: 100%;
}

li {
  list-style: none;
  cursor: pointer;
  padding: 1em 2em;
  transition: background-color 0.3s ease, transform 0.3s ease, border-radius 0.3s ease;
  border-radius: 10px; /* Bordes redondeados */
  font-size: 1.2em; /* Aumentar el tamaño de la fuente */
  white-space: nowrap; /* Evitar que el texto se divida en varias líneas */
}

li:hover {
  background-color: #00bfff; /* Azul celeste */
  transform: scale(1.1);
  border-radius: 20px; /* Más redondeado al hacer hover */
}

a {
  
  text-decoration: none; /* Eliminar subrayado de los enlaces */
  color: #ffffff; /* Cambiar el color del texto a blanco */
}

.login-button a, .login-button button {
  background-color: #00bfff; /* Azul celeste */
  padding: 0.5em 1em;
  border-radius: 10px; /* Bordes redondeados */
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}


.develop {
  position: relative;
}

.ul-second {
  display: none;
  position: absolute;
  top: 100%; /* Mostrar debajo del elemento padre */
  left: 50%; /* Centrar horizontalmente */
  transform: translateX(-50%);
  text-align: center;
  
  width: max-content; /* Ajustar al contenido */
  padding: 0.5em 0;
}

.develop:hover > .ul-second {
  display: block;
}

.ul-second.is-active {
  display: block;
  
}

.ul-second > li {
  width: 100%;
  padding: 0.5em 1em;
  margin: 0 auto;
  border-bottom: solid 1px #fff;
  text-align: center;
  background-color: #24252a;
  border-radius: 10px; /* Bordes redondeados */
  font-size: 0.9em; /* Ajustar el tamaño de la fuente */
  white-space: nowrap; /* Evitar que el texto se divida en varias líneas */
}

.ul-second > li:hover {
  background-color: #00bfff;
  border-radius: 20px; /* Más redondeado al hacer hover */
}

/* Estilos para pantallas pequeñas */
@media (max-width: 900px) {
  nav {
    justify-content: space-between;
    padding: 0.5em 1em;
  }

  .hamburger-menu {
    display: flex;
  }

  .cont-ul {
    flex-direction: column;
    align-items: center;
    justify-content: space-around; /* Distribuir el espacio verticalmente */
    width: 100%;
    height: 100vh; /* Ocupar todo el alto de la pantalla */
    background-color: #24252a; /* Fondo del menú */
    position: fixed; /* Fijar el menú */
    top: 0; /* Asegurar que esté en la parte superior */
    left: 0; /* Asegurar que esté alineado a la izquierda */
    padding: 0; /* Eliminar el padding superior */
    display: none; /* Oculto por defecto */
    z-index: 1000; /* Asegurarse de que esté por encima de otros elementos */
  }

  .cont-ul.is-active {
    display: flex;
  }

  li {
    width: 100%;
    padding: 1em 0; /* Aumentar el padding vertical */
    font-size: 1.5em; /* Aumentar el tamaño de la fuente */
    text-align: center; /* Centrar el texto */
  }
}
</style>
