<template>
  <div class="map-page">
    <MenuNavegacion />
    <div class="map-container">
         <div ref="map" class="map"></div>
    </div>
    <div v-if="showPinModal" class="modal" @click.self="closeModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <p><strong>Location:</strong> {{ selectedPin.place }}</p>
        <p><strong>Date:</strong> {{ selectedPin.date }}</p>
        <p><strong>Description:</strong> {{ selectedPin.description }}</p>
        <img v-if="selectedPin.imageUrl" :src="selectedPin.imageUrl" alt="Pin Image" class="pin-image"/>
        <button class="blue-button" @click="deletePin(selectedPin.id)">Delete Pin</button>
      </div>
    </div>
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import { mapGetters, mapActions } from 'vuex';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import chinchetaIcon from '@/assets/chincheta.png';

export default {
  name: 'MapView',
  components: {
    MenuNavegacion,
  },
  data() {
    return {
      map: null,
      markers: [],
      showPinModal: false,
      selectedPin: null,
    };
  },
  computed: {
    ...mapGetters('map', ['pins'])
  },
  methods: {
    ...mapActions('map', ['fetchPins', 'deletePin']),
    initializeMap() {
      if (this.map) {
        this.map.remove();
      }

      this.map = L.map(this.$refs.map).setView([20, -0.09], 2);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map);

      const pinIcon = L.icon({
        iconUrl: chinchetaIcon,
        iconSize: [38, 38], // Tamaño del icono
        iconAnchor: [19, 38], // Punto del icono que corresponde a la ubicación del marcador
      });

      this.pins.forEach(pin => {
        if (pin.lat !== undefined && pin.lng !== undefined) {
          const marker = L.marker([pin.lat, pin.lng], { icon: pinIcon })
            .addTo(this.map)
            .on('click', () => {
              this.selectedPin = pin;
              this.showPinModal = true;
            });
          this.markers.push(marker);
        } else {
          console.error('Invalid pin position:', pin);
        }
      });
    },
    closeModal() {
      this.showPinModal = false;
      this.selectedPin = null;
    },
    async deletePin(pinId) {
      const currentZoom = this.map.getZoom();
      const currentCenter = this.map.getCenter();

      await this.$store.dispatch('map/deletePin', pinId);
      this.closeModal();
      this.refreshMarkers();

      this.map.setView(currentCenter, currentZoom); // Mantener la posición y el zoom actual
    },
    refreshMarkers() {
      this.markers.forEach(marker => this.map.removeLayer(marker));
      this.markers = [];
      this.initializeMap();
    }
  },
  async mounted() {
    await this.fetchPins();
    // Ensure pins are loaded before initializing the map
    if (this.pins && this.pins.length) {
      this.initializeMap();
    }
  },
  watch: {
    pins() {
      this.refreshMarkers();
    }
  }
};
</script>

<style scoped>
.map-page {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.map-container {
  flex: 1;
  padding-top: 70px; /* Margen superior para el menú */
  background-color: white;
  display: flex;
  flex-direction: column;
}

.map {
  flex: 1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 500;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  color: #000;
}

.blue-button {
  background-color: #00bfff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.blue-button:hover {
  background-color: #009acc;
}

.pin-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 10px;
}
</style>