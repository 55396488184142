// src/data/cartas.js
export const cartas = [
  `¡Hola, mi amor!

Espero que estés teniendo un día maravilloso, aunque sé que cualquier día mejora con solo pensar en ti. No podía dejar pasar la oportunidad de escribirte estas palabras que salen directamente de mi corazón.

Amor, no hay un solo día en que no me maraville por lo increíble que eres. Desde que te conocí, mi vida ha cambiado de una manera que nunca imaginé posible. Tu risa, tu forma de ver el mundo, tu manera de hacerme sentir amado… todo de ti me hace querer ser una mejor persona.

Te amo con una intensidad que a veces ni yo mismo puedo comprender. Es un amor que crece y se fortalece con cada momento que pasamos juntos. No puedo esperar a verte cada día, a compartir nuestras alegrías y nuestras penas, a apoyarte en todo lo que haces y a construir recuerdos que duren toda la vida.

Quiero que sepas que no puedo imaginar mi vida sin ti. Quiero pasar el resto de mis días a tu lado, despertarme contigo cada mañana y acostarme a tu lado cada noche. Por eso, quiero pedirte algo que llevo tiempo pensando: ¿Te casarías conmigo? Sé que juntos podemos construir una vida llena de amor, risas y felicidad.

Sueño con el día en que caminemos juntos hacia nuestro futuro, con el día en que nos prometamos amor eterno frente a nuestros seres queridos. Quiero formar una familia contigo, crear un hogar lleno de amor y calidez, donde nuestros hijos puedan crecer rodeados de todo el amor que tenemos para darles.

Mi amor por ti no tiene límites y cada día me siento más afortunado de tenerte a mi lado. Quiero pasar cada segundo de mi vida amándote y haciéndote [feliz]. Así que aquí estoy, abriendo mi corazón y soñando con el día en que podamos llamarnos marido y mujer.

Te amo más de lo que las palabras pueden expresar y espero con todo mi ser que compartas este sueño conmigo.`,

  `Amorr!!!!

Hace ya un año que nos conocimos mi vida y aquí estamos en el mismo sitio, a la misma hora y con las mismas ganas que teníamos el año pasado de conocernos y estar juntos.

Aquí fue donde te besé por primera vez y por sorpresa para mí no me agobié, sino que quería más y más y más, porque eres única y porque has estado en todo momento apoyándome. Sabes que esto acaba de empezar y que aún quedan muchos proyectos por hacer juntos. Hijos, bodas, familia... Todo a tu lado y siempre con la sonrisa y la confianza que tenemos el uno con el otro.

FELICIDADESSS. FELICES 27 AÑOSSS Y [TE AMOOO] CON LOCURA :)

Eres mi vida!!`
];