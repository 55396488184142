<template>
  <div>
    <h1>Login with Spotify</h1>
    <button @click="login">Login</button>
  </div>
</template>

<script>
export default {
  methods: {
    login() {
      const clientId = '90cd7f24442f44618d240d7eaa7849a9'; // Reemplaza con tu Client ID
      const redirectUri = 'https://www.nuestronido.com/music';
      const scopes = [
        'user-read-private',
        'user-read-email',
        'streaming',
        'user-read-playback-state',
        'user-modify-playback-state',
        'playlist-read-private',
        'playlist-modify-private',
        'playlist-modify-public'
      ].join(' ');

      const url = `https://accounts.spotify.com/authorize?response_type=token&client_id=${clientId}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(redirectUri)}`;

      window.location.href = url;
    }
  }
};
</script>