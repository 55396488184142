// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import LoginView from '@/views/LoginView.vue';
import AdminPanel from '@/views/AdminPanel.vue';
import GalleryView from '@/views/GalleryView.vue';
import AlbumView from '@/views/AlbumView.vue';
import MapView from '@/views/MapView.vue';
import CalendarView from '@/views/CalendarView.vue';
import DeseosView from '@/views/DeseosView.vue';
import MusicView from '@/views/MusicView.vue';
import CallbackView from '@/views/CallbackView.vue';
import { auth } from '@/config/firebaseClient';

const routes = [
  {
    path: '/',
    name: 'WelcomeView',
    component: WelcomeView,
  },
  {
    path: '/home',
    name: 'HomeView', // Cambiado de 'Home' a 'HomeView'
    component: HomeView,
  },
  {
    path: '/callback',
    name: 'CallbackView', // Cambiado de 'Home' a 'HomeView'
    component: CallbackView,
  },
  {
    path: '/wishlist',
    name: 'DeseosView',
    component: DeseosView,
  },
  {
    path: '/music',
    name: 'Music',
    component: MusicView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPanel,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gallery',
    name: 'GalleryView',
    component: GalleryView,
  },
  {
    path: '/calendar',
    name: 'CalendarView',
    component: CalendarView,
  },
  {
    path: '/map',
    name: 'MapView',
    component: MapView,
  },
  {
    path: '/album/:id',
    name: 'AlbumView',
    component: AlbumView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    next('/login');
  } else {
    next();
  }
});

export default router;
