import { createStore } from 'vuex';
import auth from './modules/auth';
import gallery from './modules/gallery';
import calendar from './modules/calendar';
import map from './modules/map';
import wishes from './modules/wishes';

export default createStore({
  modules: {
    auth,
    gallery,
    calendar,
    wishes,
    map,
    
  }
});
