import { db } from '@/config/firebaseClient';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';

const state = {
  pins: []
};

const getters = {
  pins: state => state.pins
};

const actions = {
  async fetchPins({ commit }) {
    const querySnapshot = await getDocs(collection(db, 'pins'));
    const pins = [];
    querySnapshot.forEach(doc => {
      pins.push({ id: doc.id, ...doc.data() });
    });
    commit('setPins', pins);
  },
  async addPin({ dispatch }, pin) {
    await addDoc(collection(db, 'pins'), pin);
    dispatch('fetchPins');
  },
  async deletePin({ commit, state }, pinId) {
    await deleteDoc(doc(db, 'pins', pinId));
    const updatedPins = state.pins.filter(pin => pin.id !== pinId);
    commit('setPins', updatedPins);
  }
};

const mutations = {
  setPins(state, pins) {
    state.pins = pins;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};