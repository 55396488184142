import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDsgBXK1gmR5iFH0k2O6BF2HeNhzNjQVzo",
  authDomain: "amor-61bdb.firebaseapp.com",
  projectId: "amor-61bdb",
  storageBucket: "amor-61bdb.appspot.com",
  messagingSenderId: "906016553124",
  appId: "1:906016553124:web:5e21ae8d4cc5e3fc3c0e31",
  measurementId: "G-F5Q3T83JFK"
};

// Verificar si ya existe una instancia inicializada de Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // Si ya está inicializado, usa esa instancia
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

export { auth, db, storage };
