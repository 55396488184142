<template>
  <div>
    <h2>Musica para ti</h2>
    <p v-if="trackId">Playing track ID: {{ trackId }}</p>
    <div id="spotify-player"></div>
  </div>
</template>

<script>
export default {
  props: ['trackId'],
  data() {
    return {
      player: null,
      deviceId: null,
    };
  },
  watch: {
    trackId(newTrackId) {
      if (newTrackId && this.deviceId) {
        this.playTrack(newTrackId);
      }
    }
  },
  async mounted() {
    await this.loadSpotifySDK();
    if (window.Spotify) {
      this.initializePlayer();
    }
  },
  methods: {
    async loadSpotifySDK() {
      return new Promise((resolve) => {
        if (window.Spotify) {
          resolve();
        } else {
          window.onSpotifyWebPlaybackSDKReady = resolve;
          const script = document.createElement('script');
          script.src = 'https://sdk.scdn.co/spotify-player.js';
          script.async = true;
          document.body.appendChild(script);
        }
      });
    },
    initializePlayer() {
      const token = localStorage.getItem('spotify_access_token');
      this.player = new window.Spotify.Player({
        name: 'Vue Spotify Player',
        getOAuthToken: cb => { cb(token); },
        volume: 0.5
      });

      // Ready
      this.player.addListener('ready', ({ device_id }) => {
        this.deviceId = device_id;
        console.log('Ready with Device ID', device_id);
        if (this.trackId) {
          this.playTrack(this.trackId);
        }
      });

      // Not Ready
      this.player.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
      });

      // Track ended
      this.player.addListener('player_state_changed', state => {
        if (!state) {
          return;
        }
        if (state.paused && state.position === 0 && state.restrictions?.disallow_resuming_reasons?.includes('not_paused')) {
          this.$emit('track-ended');
        }
      });

      this.player.connect();
    },
    playTrack(trackId) {
      const token = localStorage.getItem('spotify_access_token');
      fetch(`https://api.spotify.com/v1/me/player/play?device_id=${this.deviceId}`, {
        method: 'PUT',
        body: JSON.stringify({ uris: [`spotify:track:${trackId}`] }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        if (response.status === 204) {
          console.log('Track is playing');
        } else {
          console.error('Failed to play track', response);
        }
      });
    }
  }
};
</script>