<template>
  <div class="music-view">
    <MenuNavegacion />
    <div class="content">
      <LoginComp v-if="!accessToken" />
      <div v-else>
        <SearchComp @track-selected="updateTrack" class="search-comp" />
        <SpotifyPlayer :trackId="currentTrackId" @track-ended="playNextTrackInPlaylist" class="spotify-player" />
        <div class="music-player-wrapper">
          <MusicPlayer 
            :trackId="currentTrackId" 
            ref="musicPlayer" 
            @next-track="playNextTrack" 
            @previous-track="playPreviousTrack"
            @track-ended="playNextTrackInPlaylist" />
        </div>
        <PlaylistComp 
          @playlist-selected="playPlaylist" 
          @track-selected="playSong" 
          @playlists-updated="fetchPlaylists" 
          ref="playlistComp" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginComp from '@/components/LoginComp.vue';
import SearchComp from '@/components/SearchComp.vue';
import SpotifyPlayer from '@/components/SpotifyPlayer.vue';
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import MusicPlayer from '@/components/MusicPlayer.vue';
import PlaylistComp from '@/components/PlaylistComp.vue';

export default {
  components: {
    LoginComp,
    SearchComp,
    SpotifyPlayer,
    MenuNavegacion,
    MusicPlayer,
    PlaylistComp,
  },
  data() {
    return {
      accessToken: localStorage.getItem('spotify_access_token'),
      currentTrackId: null,
      playlists: [],
      hasRedirected: false, // Flag to prevent multiple redirections
      currentPlaylistId: null,
      currentTrackIndex: 0,
      playlistTracks: [],
    };
  },
  methods: {
    updateTrack(trackId) {
      console.log('Updating track to:', trackId);
      this.currentTrackId = trackId;
    },
    handleTokenExpiration() {
      console.log('Handling token expiration');
      if (!this.hasRedirected) {
        localStorage.removeItem('spotify_access_token');
        localStorage.removeItem('spotify_refresh_token');
        this.accessToken = null;
        this.hasRedirected = true;
      }
    },
    async playSong(trackUri) {
      try {
        console.log('Playing song:', trackUri);
        const deviceId = this.$refs.musicPlayer.deviceId;
        if (!deviceId) {
          console.error('Device ID is not available.');
          return;
        }

        let token = localStorage.getItem('spotify_access_token');
        if (!token) {
          token = await this.$refs.musicPlayer.refreshAccessToken();
        }

        const response = await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ uris: [trackUri] })
        });

        if (response.ok) {
          console.log('Track is playing');
        } else if (response.status === 401) {
          this.handleTokenExpiration();
        } else {
          console.error('Failed to play track', response);
        }
      } catch (error) {
        console.error('Error playing track', error);
        if (error.response && error.response.status === 401) {
          this.handleTokenExpiration();
        }
      }
    },
    async playPlaylist(playlistId) {
      try {
        console.log('Playing playlist:', playlistId);
        const deviceId = this.$refs.musicPlayer.deviceId;
        if (!deviceId) {
          console.error('Device ID is not available.');
          return;
        }

        let token = localStorage.getItem('spotify_access_token');
        if (!token) {
          token = await this.$refs.musicPlayer.refreshAccessToken();
        }

        const response = await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ context_uri: `spotify:playlist:${playlistId}` })
        });

        if (response.ok) {
          console.log('Playlist is playing');
          this.currentPlaylistId = playlistId;
          this.currentTrackIndex = 0;
          await this.fetchPlaylistTracks(playlistId); // Fetch tracks of the selected playlist
        } else if (response.status === 401) {
          this.handleTokenExpiration();
        } else {
          console.error('Failed to play playlist', response);
        }
      } catch (error) {
        console.error('Error playing playlist', error);
        if (error.response && error.response.status === 401) {
          this.handleTokenExpiration();
        }
      }
    },
    async fetchPlaylistTracks(playlistId) {
      try {
        console.log('Fetching tracks for playlist:', playlistId);
        let token = localStorage.getItem('spotify_access_token');
        const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        this.playlistTracks = data.items.map(item => item.track);
        console.log('Fetched tracks:', this.playlistTracks);
      } catch (error) {
        console.error('Failed to fetch playlist tracks', error);
      }
    },
    playNextTrack() {
      console.log('Playing next track');
      this.$refs.playlistComp.playNextTrack();
    },
    playPreviousTrack() {
      console.log('Playing previous track');
      this.$refs.playlistComp.playPreviousTrack();
    },
    async playNextTrackInPlaylist() {
      console.log('Playing next track in playlist');
      if (!this.playlistTracks.length) return;
      this.currentTrackIndex = (this.currentTrackIndex + 1) % this.playlistTracks.length;
      const nextTrack = this.playlistTracks[this.currentTrackIndex];
      console.log('Next track:', nextTrack);
      this.currentTrackId = nextTrack.id;
      await this.playSong(nextTrack.uri);
    },
    async fetchPlaylists() {
      try {
        console.log('Fetching playlists');
        const accessToken = localStorage.getItem('spotify_access_token');
        const response = await fetch('https://api.spotify.com/v1/me/playlists', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          this.playlists = data.items;
          console.log('Fetched playlists:', this.playlists);
        } else if (response.status === 401) {
          this.handleTokenExpiration();
        }
      } catch (error) {
        console.error('Error fetching playlists', error);
        if (error.response && error.response.status === 401) {
          this.handleTokenExpiration();
        }
      }
    },
    extractTokenFromUrl() {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.substring(1)); // Remove the `#` at the start
      const accessToken = params.get('access_token');
      if (accessToken) {
        localStorage.setItem('spotify_access_token', accessToken);
        this.accessToken = accessToken;
        window.location.hash = ''; // Clear the hash part of the URL
      }
    }
  },
  async mounted() {
    this.extractTokenFromUrl();
    if (this.accessToken) {
      await this.fetchPlaylists();
    }
  }
};
</script>

<style scoped>
.music-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 90%;
  max-width: 800px;
  margin: 6rem auto 6rem; /* Adjusted to leave space for the music player */
  text-align: center;
}

.search-comp {
  margin-bottom: 2rem;
}

.spotify-player {
  margin-top: 2rem;
}

.music-player-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 500; /* Lower z-index to ensure it's behind the menu */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

/* Responsive styles */
@media (max-width: 600px) {
  .content {
    width: 95%;
    margin-bottom: 8rem; /* Increased to accommodate the fixed music player */
  }
  .music-player-wrapper {
    padding: 0.5rem;
    transform: translateX(-6%); /* Adjust translation for smaller screens */
  }
}
</style>