<template>
  <div class="letter-container" @click="revealFullLetter">
    <div class="letter">
      <button v-if="!isMobile" class="reveal-button" @click.stop="revealFullLetter">Mostrar completa</button>
      <span ref="letterContent"></span>
    </div>
  </div>
</template>

<script>
import { cartas } from '@/data/cartas.js';

export default {
  name: 'CartaAnimada',
  data() {
    return {
      letterText: '',
      typingTimeout: null, // Variable to store the timeout ID
      isMobile: false
    };
  },
  methods: {
    getRandomLetter() {
      const randomIndex = Math.floor(Math.random() * cartas.length);
      this.letterText = cartas[randomIndex];
    },
    typeWriterEffect() {
      const content = this.$refs.letterContent;
      let index = 0;

      const type = () => {
        if (index < this.letterText.length) {
          content.textContent += this.letterText.charAt(index); // Use textContent instead of innerHTML
          index++;
          this.typingTimeout = setTimeout(type, 30); // Adjust typing speed here
        }
      };

      type();
    },
    revealFullLetter() {
      clearTimeout(this.typingTimeout); // Clear the typing animation
      this.$refs.letterContent.textContent = this.letterText; // Display the full letter
    },
    applyHighlight() {
      const content = this.$refs.letterContent;
      let text = content.textContent;

      // Regex pattern to match text inside []
      const pattern = /\[(.*?)\]/g;
      text = text.replace(pattern, '<span class="highlight">$1</span>');

      content.innerHTML = text; // Update the content with highlighted text
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 600;
    }
  },
  mounted() {
    this.getRandomLetter();
    this.typeWriterEffect();
    this.$nextTick(() => {
      this.applyHighlight();
    });
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.letter-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.letter {
  font-family: 'Roboto', sans-serif;
  font-size: 1.4em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.letter::after {
  content: '|';
  animation: blink 0.7s infinite;
}

.reveal-button {
  background-color: #00bfff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.reveal-button:hover {
  background-color: #009acc;
}

@media (min-width: 600px) {
  .reveal-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.highlight {
  background-color: #e0f7fa; /* Azul celeste */
  text-decoration: underline;
  padding: 0 4px; /* Añadir un pequeño espaciado para separar las palabras */
}
</style>