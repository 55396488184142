<template>
  <div class="music-player">
    <div class="controls">
      <button @click="togglePlayPause">
        <span v-if="!isPlaying">Play</span>
        <span v-else>Pause</span>
      </button>
      <button @click="playPrevious">Previous</button>
      <button @click="playNext">Next</button>
    </div>
    <div class="timeline">
      <input type="range" min="0" :max="duration" v-model="currentTime" @input="seek" />
      <div class="time">
        <span>{{ formatTime(currentTime) }}</span> / <span>{{ formatTime(duration) }}</span>
      </div>
    </div>
    <div class="song-title">{{ songTitle }}</div>
  </div>
</template>

<script>
let playerInstance = null;
let deviceId = null;

export default {
  props: ['trackId'],
  data() {
    return {
      player: null,
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      interval: null,
      songTitle: '',
      initialToken: localStorage.getItem('spotify_access_token')
    };
  },
  watch: {
    trackId(newTrackId) {
      console.log('trackId changed:', newTrackId);
      if (newTrackId) {
        this.playTrack(newTrackId);
      }
    },
    isPlaying(newIsPlaying) {
      console.log('isPlaying changed:', newIsPlaying);
      if (newIsPlaying) {
        this.startTrackingTime();
      } else {
        this.stopTrackingTime();
      }
    }
  },
  async mounted() {
    console.log('MusicPlayer mounted');
    await this.initializePlayer();
    if (this.trackId) {
      await this.playTrack(this.trackId);
    }
  },
  beforeUnmount() {
    console.log('MusicPlayer beforeUnmount');
    this.stopTrackingTime();
  },
  methods: {
    async loadSpotifySDK() {
      return new Promise((resolve) => {
        if (window.Spotify) {
          resolve();
        } else {
          window.onSpotifyWebPlaybackSDKReady = resolve;
          const script = document.createElement('script');
          script.src = 'https://sdk.scdn.co/spotify-player.js';
          script.async = true;
          document.body.appendChild(script);
        }
      });
    },
    async initializePlayer() {
      if (playerInstance) {
        this.player = playerInstance;
        deviceId = this.player._options.id;
        this.deviceId = deviceId;
        return;
      }

      await this.loadSpotifySDK();

      if (!window.Spotify || !window.Spotify.Player) {
        console.error('Spotify SDK not loaded.');
        return;
      }

      let token = localStorage.getItem('spotify_access_token');
      if (!token) {
        token = await this.refreshAccessToken();
      }

      this.player = new window.Spotify.Player({
        name: 'Vue Spotify Player',
        getOAuthToken: cb => { cb(token); },
        volume: 0.5
      });

      this.player.addListener('ready', ({ device_id }) => {
        console.log('Player ready with Device ID', device_id);
        deviceId = device_id;
        this.deviceId = device_id;
      });

      this.player.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
        deviceId = null;
        this.deviceId = null;
      });

      this.player.addListener('player_state_changed', state => {
        console.log('Player state changed:', state);
        if (state) {
          this.isPlaying = !state.paused;
          this.duration = state.duration / 1000;
          this.currentTime = state.position / 1000;

          if (state.track_window?.current_track) {
            this.songTitle = state.track_window.current_track.name;
          } else {
            this.songTitle = '';
          }

          // Emitir un evento cuando la pista termine
          if (state.paused && state.position === 0 && state.track_window.previous_tracks.length > 0) {
            console.log('Track ended');
            this.$emit('track-ended');
          }
        }
      });

      this.player.addListener('initialization_error', ({ message }) => {
        console.error('Failed to initialize', message);
      });

      this.player.addListener('authentication_error', async ({ message }) => {
        console.error('Failed to authenticate', message);
        await this.handleTokenExpiration(); // Manejar la expiración del token
      });

      this.player.addListener('account_error', ({ message }) => {
        console.error('Failed to validate Spotify account', message);
      });

      this.player.addListener('playback_error', ({ message }) => {
        console.error('Failed to perform playback', message);
      });

      await this.player.connect();
      playerInstance = this.player;
    },
    async handleTokenExpiration() {
      try {
        console.log('Handling token expiration');
        await this.refreshAccessToken();
        this.player.disconnect(); // Desconectar el reproductor anterior
        await this.initializePlayer(); // Volver a inicializar el reproductor con el nuevo token
      } catch (error) {
        console.error('Failed to refresh token and reinitialize player', error);
      }
    },
    async refreshAccessToken() {
      console.log('Refreshing access token');
      const refreshToken = localStorage.getItem('spotify_refresh_token');
      const clientId = '90cd7f24442f44618d240d7eaa7849a9';
      const clientSecret = 'f4d79aab160d422fa7441645f16555d4';

      const response = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret)
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: refreshToken
        })
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('spotify_access_token', data.access_token);
        return data.access_token;
      } else {
        console.error('Failed to refresh access token', response);
        throw new Error('Failed to refresh access token');
      }
    },
    async playTrack(trackId) {
      try {
        console.log('Playing track:', trackId);
        if (!this.deviceId) {
          console.log('Waiting for device ID...');
          await this.waitForDeviceId();
        }

        let token = localStorage.getItem('spotify_access_token');
        if (!token) {
          token = await this.refreshAccessToken();
        }

        const response = await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${this.deviceId}`, {
          method: 'PUT',
          body: JSON.stringify({ uris: [`spotify:track:${trackId}`] }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 204) {
          console.log('Track is playing');
        } else {
          console.error('Failed to play track', response);
        }
      } catch (error) {
        console.error('Error playing track', error);
      }
    },
    async waitForDeviceId() {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.deviceId) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    },
    togglePlayPause() {
      console.log('Toggling play/pause');
      if (this.isPlaying) {
        this.player.pause().then(() => {
          this.isPlaying = false;
        });
      } else {
        this.player.resume().then(() => {
          this.isPlaying = true;
        });
      }
    },
    seek(event) {
      const newTime = event.target.value;
      console.log('Seeking to:', newTime);
      this.player.seek(newTime * 1000).then(() => {
        this.currentTime = newTime;
      });
    },
    playPrevious() {
      console.log('Playing previous track');
      this.$emit('previous-track');
    },
    playNext() {
      console.log('Playing next track');
      this.$emit('next-track');
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },
    startTrackingTime() {
      if (!this.interval) {
        console.log('Starting tracking time');
        this.interval = setInterval(() => {
          this.player.getCurrentState().then(state => {
            if (state) {
              this.currentTime = state.position / 1000;
            }
          });
        }, 1000);
      }
    },
    stopTrackingTime() {
      if (this.interval) {
        console.log('Stopping tracking time');
        clearInterval(this.interval);
        this.interval = null;
      }
    }
  }
};
</script>

<style scoped>
.music-player {
  width: 100%;
  padding: 1rem;
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.music-player:hover {
  transform: scale(1.05);
}

.controls {
  margin-bottom: 0.5rem;
}

button {
  background-color: #00bfff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #009fcc;
  transform: scale(1.1);
}

.timeline {
  width: 100%;
  max-width: 500px;
}

input[type="range"] {
  width: 100%;
  margin: 0.5rem 0;
}

.time {
  display: flex;
  justify-content: space-between;
}

.song-title {
  font-family: 'Roboto', sans-serif;
  margin-top: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 600px) {
  .music-player {
    width: 80%;
    left: 10%;
    border-radius: 10px;
  }
}
</style>