<template>
  <div>
    <p>Processing...</p>
  </div>
</template>

<script>
export default {
  created() {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');

    if (accessToken) {
      localStorage.setItem('spotify_access_token', accessToken);
      this.$router.push('/music');
    } else {
      console.error('No access token found in URL parameters.');
      this.$router.push('/');
    }
  }
}
</script>