// store/modules/wishes.js

const state = {
  wishes: JSON.parse(localStorage.getItem('wishes')) || []
};

const getters = {
  allWishes: (state) => state.wishes
};

const actions = {
  addWish({ commit }, wish) {
    console.log('Action addWish:', wish);
    commit('newWish', wish);
  },
  deleteWish({ commit }, id) {
    console.log('Action deleteWish:', id);
    commit('removeWish', id);
  },
  completeWish({ commit }, id) {
    console.log('Action completeWish:', id);
    commit('completeWish', id);
  }
};

const mutations = {
  newWish: (state, wish) => {
    console.log('Mutation newWish:', wish);
    state.wishes.push(wish);
    localStorage.setItem('wishes', JSON.stringify(state.wishes));
  },
  removeWish: (state, id) => {
    console.log('Mutation removeWish:', id);
    state.wishes = state.wishes.filter(wish => wish.id !== id);
    localStorage.setItem('wishes', JSON.stringify(state.wishes));
  },
  completeWish: (state, id) => {
    console.log('Mutation completeWish:', id);
    const wish = state.wishes.find(wish => wish.id === id);
    if (wish) {
      wish.completed = true;
      localStorage.setItem('wishes', JSON.stringify(state.wishes));
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};