<template>
  <div class="contador-container">
    <div class="contador">
      <div class="contador-item">{{ years }} años</div>
      <div class="contador-item">{{ months }} meses</div>
      <div class="contador-item">{{ days }} días</div>
      <div class="contador-item">{{ hours }} horas</div>
      <div class="contador-item">{{ minutes }} minutos</div>
      <div class="contador-item">{{ seconds }} segundos</div>
    </div>
    <div class="junto-a-ti">Junto a ti</div>
  </div>
</template>

<script>
export default {
  name: 'MiContador',
  data() {
    return {
      startDate: new Date('2023-07-27T00:00:00'), // Fecha y hora de inicio de la relación
      currentDate: new Date()
    }
  },
  computed: {
    timeDiff() {
      const diff = this.currentDate - this.startDate;
      const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
      const months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
      const days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      return { years, months, days, hours, minutes, seconds };
    },
    years() {
      return this.timeDiff.years;
    },
    months() {
      return this.timeDiff.months;
    },
    days() {
      return this.timeDiff.days;
    },
    hours() {
      return this.timeDiff.hours;
    },
    minutes() {
      return this.timeDiff.minutes;
    },
    seconds() {
      return this.timeDiff.seconds;
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentDate = new Date();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evitar el scroll horizontal */
}

.contador-container {
  text-align: center;
  margin-top: 150px; /* Margen superior para separar del menú de navegación */
  padding: 0 1em;
}

.contador {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: nowrap;
  overflow-x: hidden; /* Evitar el scroll horizontal en el contenedor */
}

.contador-item {
  background-color: #00bfff; /* Color celeste */
  color: white;
  border-radius: 10px; /* Bordes redondeados */
  padding: 10px 20px;
  margin: 0 10px;
  text-align: center;
  min-width: 100px; /* Asegurar que los elementos tengan un tamaño mínimo */
}

.junto-a-ti {
  font-size: 2em;
  color: white; /* Color blanco */
  font-weight: bold;
  font-family: 'Roboto', sans-serif; /* Fuente Roboto */
}

@media (max-width: 1200px) {
  .contador {
    flex-wrap: wrap; /* Permitir el ajuste de los elementos en pantallas más pequeñas */
    font-size: 1.5em;
  }

  .contador-item {
    flex: 1 1 calc(33% - 20px); /* Ajuste flexible para cada elemento */
    min-width: 100px;
  }

  .junto-a-ti {
    font-size: 1.5em;
  }
}

@media (max-width: 900px) {
  .contador {
    font-size: 1.2em;
  }

  .contador-item {
    flex: 1 1 100%;
    margin: 5px 0;
  }

  .junto-a-ti {
    font-size: 1.2em;
  }
}

@media (max-width: 600px) {
  .contador {
    font-size: 1em;
  }

  .junto-a-ti {
    font-size: 1em;
  }
}
</style>
