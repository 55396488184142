<template>
  <div class="album-page">
    <MenuNavegacion />
    <div class="album-container">
      <h1>{{ album.name }}</h1>
      <div v-if="photos.length" class="photos">
        <div v-for="photo in uniquePhotos" :key="photo.id" class="photo" @click="openPhoto(photo)">
          <img :src="photo.url" :alt="photo.name" />
        </div>
      </div>
      <div v-else>
        <p>No photos in this album.</p>
      </div>
    </div>
    <div v-if="selectedPhoto" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="closeModal">X</button>
        <button class="nav-button left" @click="prevPhoto">&lt;</button>
        <img :src="selectedPhoto.url" :alt="selectedPhoto.name" />
        <button class="nav-button right" @click="nextPhoto">&gt;</button>
        <button class="delete-button" @click="confirmDeletePhoto(selectedPhoto.id)">
          <img src="@/assets/trash-icon.png" alt="Delete" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AlbumView',
  components: {
    MenuNavegacion,
  },
  data() {
    return {
      album: {},
      selectedPhoto: null
    };
  },
  computed: {
    ...mapGetters('gallery', ['albums', 'photos']),
    uniquePhotos() {
      return this.photos.filter((photo, index, self) =>
        index === self.findIndex((t) => (
          t.id === photo.id
        ))
      );
    }
  },
  methods: {
    ...mapActions('gallery', ['fetchPhotos', 'deletePhoto']),
    async loadAlbum() {
      const albumId = this.$route.params.id;
      this.album = this.albums.find(a => a.id === albumId) || {};
      await this.fetchPhotos({ albumId });
    },
    openPhoto(photo) {
      this.selectedPhoto = photo;
    },
    closeModal() {
      this.selectedPhoto = null;
    },
    confirmDeletePhoto(photoId) {
      if (confirm("¿Estás seguro de que quieres borrar esta foto?")) {
        this.deletePhoto(photoId);
      }
    },
    async deletePhoto(photoId) {
      await this.$store.dispatch('gallery/deletePhoto', { photoId });
      this.$store.commit('gallery/removePhoto', photoId);
      this.closeModal();
    },
    prevPhoto() {
      const currentIndex = this.uniquePhotos.findIndex(photo => photo.id === this.selectedPhoto.id);
      const prevIndex = (currentIndex - 1 + this.uniquePhotos.length) % this.uniquePhotos.length;
      this.selectedPhoto = this.uniquePhotos[prevIndex];
    },
    nextPhoto() {
      const currentIndex = this.uniquePhotos.findIndex(photo => photo.id === this.selectedPhoto.id);
      const nextIndex = (currentIndex + 1) % this.uniquePhotos.length;
      this.selectedPhoto = this.uniquePhotos[nextIndex];
    }
  },
  mounted() {
    this.loadAlbum();
  },
  watch: {
    '$route.params.id': 'loadAlbum',
  }
};
</script>

<style scoped>
.album-page {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden; /* Evitar el scroll horizontal */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.album-container {
  flex: 1;
  padding-top: 70px; /* Margen superior para el menú */
  padding: 20px;
  background-color: white;
  color: #ffffff;
  overflow-y: auto; /* Permitir el scroll vertical */
}

.photos {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.photo img {
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.photo:hover img {
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.delete-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: white;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
}

.delete-button img {
  width: 30px;
  height: 30px;
}

.nav-button {
  position: absolute;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.nav-button.left {
  left: 10px;
}

.nav-button.right {
  right: 10px;
}

/* Responsive Design */
@media (min-width: 600px) {
  .photos {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .photos {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>