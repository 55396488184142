<template>
  <div class="admin-page">
    <MenuNavegacion />
    <div class="admin-container">
      <h1>Admin Panel</h1>
      <div class="admin-section">
        <h2 @click="togglePhotosVideos">Fotos y Videos</h2>
        <div v-if="showPhotosVideos" class="photos-videos-section">
          <button class="admin-button" @click="showCreateAlbumForm = true">Crear Album</button>
          <input type="file" @change="handleFileUpload" multiple />
          <select v-model="selectedAlbumId">
            <option v-for="album in albums" :key="album.id" :value="album.id">
              {{ album.name }}
            </option>
          </select>
          <button class="black-button" @click="uploadFiles">Subir archivos</button>
          <div v-if="showCreateAlbumForm" class="create-album-form">
            <h3>Create Album</h3>
            <input v-model="newAlbumName" placeholder="Album Name" />
            <button class="black-button" @click="createAlbum">Crear</button>
          </div>
          <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
        </div>
      </div>
      <div class="admin-section">
        <h2 @click="toggleCalendar">Calendario</h2>
        <div v-if="showCalendar" class="calendar-section">
          <input type="date" v-model="eventDate" />
          <input type="text" v-model="eventDescription" placeholder="Descripción de la cita" />
          <input type="number" v-model="eventRating" min="1" max="10" placeholder="Pon nota!! (1-10)" />
          <button class="black-button" @click="addEvent">Añadir cita</button>
          <p v-if="calendarSuccessMessage" class="success-message">{{ calendarSuccessMessage }}</p>
        </div>
      </div>
      <div class="admin-section">
        <h2 @click="toggleMapSection">Mapa</h2>
        <div v-if="showMapSection" class="map-section">
          <div id="admin-map" class="map-container" ref="adminMap"></div>
          <input v-model="newPinPlace" placeholder="Sitio" />
          <input v-model="newPinDate" type="date" />
          <textarea v-model="newPinDescription" placeholder="Descripción"></textarea>
          <input type="file" @change="handlePinImageUpload" />
          <button class="black-button" @click="addPin">Sube ubicación</button>
          <p v-if="mapSuccessMessage" class="success-message">{{ mapSuccessMessage }}</p>
        </div>
      </div>
      <div class="admin-section">
        <h2 @click="toggleWishes">Deseos</h2>
        <div v-if="showWishes" class="wishes-section">
          <h3>Añadir Nuevo Deseo</h3>
          <input v-model="newWish" placeholder="Añadir nuevo deseo" />
          <button class="black-button" @click="addWish">Añadir Deseo</button>
          <p v-if="wishSuccessMessage" class="success-message">{{ wishSuccessMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import chinchetaIcon from '@/assets/chincheta.png';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    MenuNavegacion
  },
  data() {
    return {
      showPhotosVideos: false,
      showCreateAlbumForm: false,
      newAlbumName: '',
      selectedFiles: [],
      selectedAlbumId: null,
      successMessage: '',
      showCalendar: false,
      eventDate: '',
      eventDescription: '',
      eventRating: null,
      calendarSuccessMessage: '',
      showMapSection: false,
      newPinPlace: '',
      newPinDate: '',
      newPinDescription: '',
      newPinLat: null,
      newPinLng: null,
      newPinImage: null,
      mapSuccessMessage: '',
      map: null,
      marker: null,
      showWishes: false,
      newWish: '',
      wishSuccessMessage: ''
    };
  },
  computed: {
    ...mapGetters('gallery', ['albums']),
    ...mapGetters('map', ['pins'])
  },
  methods: {
    ...mapActions('gallery', ['createAlbum', 'uploadFiles']),
    ...mapActions('calendar', ['addEvent']),
    ...mapActions('map', ['addPin', 'fetchPins']),
    ...mapActions('wishes', ['addWish']),
    togglePhotosVideos() {
      this.showPhotosVideos = !this.showPhotosVideos;
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    async toggleMapSection() {
      this.showMapSection = !this.showMapSection;
      if (this.showMapSection) {
        await this.$nextTick(); // Ensure the DOM is updated before initializing the map
        this.initializeMap();
      }
    },
    toggleWishes() {
      this.showWishes = !this.showWishes;
    },
    handleFileUpload(event) {
      this.selectedFiles = event.target.files;
    },
    handlePinImageUpload(event) {
      this.newPinImage = event.target.files[0];
    },
    async uploadFiles() {
      if (!this.selectedAlbumId) {
        alert('Please select an album');
        return;
      }
      await this.$store.dispatch('gallery/uploadFiles', { files: this.selectedFiles, albumId: this.selectedAlbumId });
      this.successMessage = 'El archivo se ha subido correctamente';
      this.selectedFiles = [];
      this.selectedAlbumId = null;
      setTimeout(() => {
        this.successMessage = '';
      }, 3000);
    },
    async createAlbum() {
      await this.$store.dispatch('gallery/createAlbum', { name: this.newAlbumName });
      this.successMessage = 'El album se ha creado correctamente';
      this.newAlbumName = '';
      this.showCreateAlbumForm = false;
      setTimeout(() => {
        this.successMessage = '';
      }, 3000);
    },
    async addEvent() {
      if (!this.eventDate || !this.eventDescription || !this.eventRating) {
        alert('Please fill all the fields');
        return;
      }
      await this.$store.dispatch('calendar/addEvent', {
        date: this.eventDate,
        description: this.eventDescription,
        rating: this.eventRating
      });
      this.calendarSuccessMessage = 'Se han añadido los datos correctamente';
      this.eventDate = '';
      this.eventDescription = '';
      this.eventRating = null;
      setTimeout(() => {
        this.calendarSuccessMessage = '';
      }, 3000);
    },
    async addWish() {
      if (this.newWish.trim() !== '') {
        const wish = { id: Date.now(), text: this.newWish, completed: false };
        await this.$store.dispatch('wishes/addWish', wish);
        this.newWish = '';
        this.wishSuccessMessage = 'Deseo añadido correctamente';
        setTimeout(() => {
          this.wishSuccessMessage = '';
        }, 3000);
      }
    },
    initializeMap() {
      this.map = L.map(this.$refs.adminMap).setView([20, 0], 2); // Initial view of the whole world
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      this.map.on('click', this.onMapClick);
    },
    onMapClick(e) {
      if (this.marker) {
        this.map.removeLayer(this.marker);
      }
      const pinIcon = L.icon({
        iconUrl: chinchetaIcon,
        iconSize: [38, 38], // Size of the icon
        iconAnchor: [19, 38] // Point of the icon which will correspond to marker's location
      });
      this.marker = L.marker(e.latlng, { icon: pinIcon }).addTo(this.map);
      this.newPinLat = e.latlng.lat;
      this.newPinLng = e.latlng.lng;
    },
    async addPin() {
      if (!this.newPinLat || !this.newPinLng || !this.newPinPlace || !this.newPinDate || !this.newPinDescription || !this.newPinImage) {
        alert('Please fill all the fields and select a location on the map');
        return;
      }

      const storage = getStorage();
      const storageRef = ref(storage, 'pins/' + this.newPinImage.name);
      await uploadBytes(storageRef, this.newPinImage);
      const imageUrl = await getDownloadURL(storageRef);

      const newPin = {
        place: this.newPinPlace,
        date: this.newPinDate,
        description: this.newPinDescription,
        lat: this.newPinLat,
        lng: this.newPinLng,
        imageUrl: imageUrl
      };

      await this.$store.dispatch('map/addPin', newPin);
      this.mapSuccessMessage = 'Pin añadido correctamente';
      this.newPinPlace = '';
      this.newPinDate = '';
      this.newPinDescription = '';
      this.newPinImage = null;
      if (this.marker) {
        this.map.removeLayer(this.marker);
        this.marker = null;
      }
      setTimeout(() => {
        this.mapSuccessMessage = '';
      }, 3000);
    }
  },
  async mounted() {
    this.$store.dispatch('gallery/fetchAlbums');
    this.$store.dispatch('map/fetchPins');
  }
};
</script>

<style scoped>
.admin-page {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden; /* Evitar el scroll horizontal */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.admin-container {
  flex: 1;
  padding-top: 70px; /* Margen superior para el menú */
  padding: 20px;
  background-color: white;
  color: #ffffff;
}

.admin-section {
  margin-top: 20px;
}

.admin-section h2 {
  cursor: pointer;
  background-color: #00bfff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.admin-section h2:hover {
  color: #ffffff;
}

.photos-videos-section,
.calendar-section,
.map-section,
.wishes-section {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00bfff;
  padding: 20px;
  border-radius: 10px;
}

.create-album-form {
  margin-top: 20px;
}

.admin-button,
.black-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
}

.admin-button {
  background-color: #00bfff;
}

.admin-button:hover {
  background-color: #009acc;
}

.black-button {
  background-color: #000000;
}

.black-button:hover {
  background-color: #333333;
}

input,
select,
textarea {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  border: none;
  text-align: center;
  box-sizing: border-box;
}

#admin-map {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  border-radius: 10px;
}

.map-container {
  z-index: 500;
}

.success-message {
  color: green;
  margin-top: 20px;
}
</style>