<template>
  <div class="login-page">
    <MenuNavegacion />
    <div class="login-container">
      <h1>Login</h1>
      <form @submit.prevent="handleLogin">
        <input type="email" v-model="email" placeholder="Email" required />
        <input type="password" v-model="password" placeholder="Password" required />
        <button type="submit">Login</button>
      </form>
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue'; // Importar el componente de navegación
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  components: {
    MenuNavegacion, // Registrar el componente
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '' // Propiedad para manejar el mensaje de error
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    handleLogin() {
      const { email, password } = this;
      this.login({ email, password })
        .then(() => {
          this.$router.push({ name: 'HomeView' });
        })
        .catch((error) => {
          console.error('Error logging in:', error);
          this.errorMessage = 'El correo electrónico o la contraseña son incorrectos.'; // Mensaje de error
        });
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden; /* Evitar el scroll */
}

.login-page {
  height: 100vh;
  width: 100%;
  overflow-x: hidden; /* Evitar el scroll horizontal */
  display: flex;
  flex-direction: column;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1; /* Asegurar que ocupe el espacio restante debajo del menú */
  background-color: #24252a;
  color: #ffffff;
  padding: 20px; /* Agregar padding para evitar que el contenido toque los bordes */
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

input {
  margin: 10px 0;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: #fff;
}

button {
  margin: 10px 0;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  border-radius: 5px;
  border: none;
  background-color: #00bfff;
  color: #ffffff;
  cursor: pointer;
}

button:hover {
  background-color: #009acc;
}

p {
  color: red;
}

nav {
  width: 100%;
  z-index: 1000; /* Asegurarse de que el menú esté por encima del contenido */
}

/* Responsivo */
@media (max-width: 900px) {
  input, button {
    width: 90%;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5em;
  }

  input, button {
    width: 95%;
  }
}
</style>
