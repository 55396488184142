<template>
  <div class="home">
    <MenuNavegacion />
    <MiContador />
    <CartaAnimada />
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import MiContador from '@/components/MiContador.vue';
import CartaAnimada from '@/components/CartaAnimada.vue';

export default {
  name: 'HomeView',
  components: {
    MenuNavegacion,
    MiContador,
    CartaAnimada
  }
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evitar el scroll horizontal */
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden; /* Evitar el scroll horizontal */
}

.home * {
  max-width: 100%; /* Asegurar que todos los elementos hijos se ajusten al ancho del contenedor */
  box-sizing: border-box;
}
</style>