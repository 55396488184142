import { db, storage } from '@/config/firebaseClient';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";

const state = {
  albums: [],
  photos: []
};

const getters = {
  albums: (state) => state.albums,
  photos: (state) => state.photos
};

const actions = {
  async fetchAlbums({ commit }) {
    const albumsSnapshot = await getDocs(collection(db, 'albums'));
    const albums = albumsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    commit('setAlbums', albums);
  },
  async fetchPhotos({ commit }, { albumId }) {
    const photosQuery = query(collection(db, 'gallery'), where('albumId', '==', albumId));
    const photosSnapshot = await getDocs(photosQuery);
    const photos = photosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    commit('setPhotos', photos);
  },
  async uploadFiles(_, { files, albumId }) {
    const storageRef = ref(storage);
    const uploadPromises = Array.from(files).map(async (file) => {
      const fileRef = ref(storageRef, `gallery/${albumId}/${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      await addDoc(collection(db, 'gallery'), { albumId, url, name: file.name });
    });
    await Promise.all(uploadPromises);
  },
  async createAlbum(_, { name }) {
    await addDoc(collection(db, 'albums'), { name });
  },
  async deleteAlbum(_, albumId) {
    // Primero eliminamos todas las fotos asociadas al álbum
    const photosQuery = query(collection(db, 'gallery'), where('albumId', '==', albumId));
    const photosSnapshot = await getDocs(photosQuery);
    const deletePromises = photosSnapshot.docs.map(photo => deleteDoc(doc(db, 'gallery', photo.id)));
    await Promise.all(deletePromises);

    // Luego eliminamos el álbum
    await deleteDoc(doc(db, 'albums', albumId));
  },
  async deletePhoto(_, { photoId }) {
    await deleteDoc(doc(db, 'gallery', photoId));
  }
};

const mutations = {
  setAlbums(state, albums) {
    state.albums = albums;
  },
  setPhotos(state, photos) {
    state.photos = photos;
  },
  removeAlbum(state, albumId) {
    state.albums = state.albums.filter(album => album.id !== albumId);
  },
  removePhoto(state, photoId) {
    state.photos = state.photos.filter(photo => photo.id !== photoId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
