// src/store/modules/auth.js
import { auth } from '@/config/firebaseClient';

const state = {
  user: null,
  initialized: false,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  getUser: state => state.user,
  isInitialized: state => state.initialized,
};

const actions = {
  async login({ commit }, { email, password }) {
    const userCredential = await auth.signInWithEmailAndPassword(email, password);
    commit('setUser', userCredential.user);
  },
  async logout({ commit }) {
    await auth.signOut();
    commit('setUser', null);
  },
  fetchUser({ commit }) {
    return new Promise((resolve) => {
      auth.onAuthStateChanged(user => {
        if (user) {
          commit('setUser', user);
        } else {
          commit('setUser', null);
        }
        commit('setInitialized', true);
        resolve();
      });
    });
  }
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setInitialized(state, value) {
    state.initialized = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};