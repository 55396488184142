<template>
  <div class="search" ref="searchContainer">
    <input v-model="query" @keyup.enter="search" placeholder="Buscar canción" class="search-input" @focus="onFocus" />
    <ul v-if="tracks.length" class="results-list">
      <li v-for="track in tracks" :key="track.id" @click="selectTrack(track)" class="result-item">
        {{ track.name }} by {{ track.artists[0].name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'play' // default mode is 'play', other mode is 'add-to-playlist'
    }
  },
  data() {
    return {
      query: '',
      tracks: [],
      isFocused: false
    };
  },
  methods: {
    async search() {
      const accessToken = localStorage.getItem('spotify_access_token');
      if (this.query.trim() && accessToken) {
        const response = await fetch(`https://api.spotify.com/v1/search?q=${this.query}&type=track`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        const data = await response.json();
        this.tracks = data.tracks.items;
      }
    },
    selectTrack(track) {
      if (this.mode === 'play') {
        this.$emit('track-selected', track.id);
      } else if (this.mode === 'add-to-playlist') {
        this.$emit('track-selected-for-playlist', track.uri);
      }
      this.tracks = []; // Clear search results after selection
      this.isFocused = false;
    },
    onFocus() {
      this.isFocused = true;
    },
    handleClickOutside(event) {
      if (this.$refs.searchContainer && !this.$refs.searchContainer.contains(event.target)) {
        this.isFocused = false;
        this.tracks = []; // Clear search results when clicking outside
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleClickOutside);
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
.search {
  margin: 2rem 0;
  position: relative;
}

.search-input {
  width: 80%;
  padding: 0.5rem;
  border-radius: 20px; /* Rounded borders */
  background-color: rgba(173, 216, 230, 0.7); /* Light blue background with 70% opacity */
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.results-list {
  list-style-type: none; /* Remove bullets */
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 20px 20px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.result-item {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Hover animation */
}

.result-item:hover {
  background-color: #f0f0f0;
}
</style>