<template>
  <div class="playlist-comp">
    <input v-model="newPlaylistName" placeholder="Nombre de la lista" class="input-box"/>
    <button @click="addPlaylist" class="styled-button">Añadir tu lista</button>
    <ul>
      <li v-for="playlist in playlists" :key="playlist.id" class="playlist-item">
        <span @click="togglePlaylistTracks(playlist.id)" class="playlist-name">{{ playlist.name }}</span>
        <button @click="$emit('playlist-selected', playlist.id)" class="styled-button">Dale</button>
        <button @click="deletePlaylist(playlist.id)" class="styled-button">Borrar</button>
        <button @click="setSelectedPlaylist(playlist.id)" class="styled-button">Añadir</button>
        <div v-if="isPlaylistOpen(playlist.id)">
          <ul>
            <li v-for="(track, index) in playlistTracks" :key="track.id" class="track-item">
              <div @click="playTrack(track.uri, playlist.id, index)" class="track-info">
                {{ track.name }} by {{ track.artists[0].name }}
              </div>
              <button @click="deleteTrack(playlist.id, track.uri, index)" class="delete-button">Eliminar</button>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div v-if="selectedPlaylist">
      <h3>Añadir canción a la lista</h3>
      <SearchComp @track-selected-for-playlist="addSongToPlaylist" mode="add-to-playlist" class="input-box"/>
    </div>
  </div>
</template>

<script>
import SearchComp from '@/components/SearchComp.vue';

export default {
  components: {
    SearchComp
  },
  data() {
    return {
      newPlaylistName: '',
      playlists: [],
      selectedPlaylist: null,
      playlistTracks: [],
      openPlaylists: new Set(),
      currentPlaylist: null,
      currentTrackIndex: 0,
    };
  },
  methods: {
    async fetchPlaylists() {
      try {
        let token = localStorage.getItem('spotify_access_token');
        const response = await fetch('https://api.spotify.com/v1/me/playlists', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        this.playlists = data.items;
      } catch (error) {
        console.error('Failed to fetch playlists', error);
      }
    },
    async addPlaylist() {
      try {
        let token = localStorage.getItem('spotify_access_token');
        const userId = '31jnccmrmqd6nmlbdc3ieiy2w5ae'; // Replace this with the correct user ID
        const response = await fetch(`https://api.spotify.com/v1/users/${userId}/playlists`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.newPlaylistName,
            public: false // Or true, depending on your needs
          })
        });

        if (response.ok) {
          const newPlaylist = await response.json();
          this.playlists.push(newPlaylist);
          this.newPlaylistName = ''; // Clear the input field
        } else {
          console.error('Failed to add playlist', response);
        }
      } catch (error) {
        console.error('Failed to add playlist', error);
      }
    },
    async deletePlaylist(playlistId) {
      try {
        let token = localStorage.getItem('spotify_access_token');
        const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/followers`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          this.playlists = this.playlists.filter(playlist => playlist.id !== playlistId);
          this.openPlaylists.delete(playlistId); // Close the playlist if it was open
        } else {
          console.error('Failed to delete playlist', response);
        }
      } catch (error) {
        console.error('Failed to delete playlist', error);
      }
    },
    async deleteTrack(playlistId, trackUri, index) {
      try {
        let token = localStorage.getItem('spotify_access_token');
        const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            tracks: [{ uri: trackUri, positions: [index] }]
          })
        });

        if (response.ok) {
          console.log('Track deleted from playlist');
          await this.fetchPlaylistTracks(playlistId); // Refresh playlist tracks
        } else {
          console.error('Failed to delete track from playlist', response);
        }
      } catch (error) {
        console.error('Failed to delete track from playlist', error);
      }
    },
    setSelectedPlaylist(playlistId) {
      this.selectedPlaylist = playlistId;
    },
    async addSongToPlaylist(songUri) {
      if (!songUri || !this.selectedPlaylist) {
        return;
      }
      try {
        let token = localStorage.getItem('spotify_access_token');
        const response = await fetch(`https://api.spotify.com/v1/playlists/${this.selectedPlaylist}/tracks`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uris: [songUri]
          })
        });

        if (response.ok) {
          console.log('Song added to playlist');
          await this.fetchPlaylistTracks(this.selectedPlaylist); // Refresh playlist tracks
        } else {
          console.error('Failed to add song to playlist', response);
        }
      } catch (error) {
        console.error('Failed to add song to playlist', error);
      }
    },
    async fetchPlaylistTracks(playlistId) {
      try {
        let token = localStorage.getItem('spotify_access_token');
        const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        this.playlistTracks = data.items.map(item => item.track);
      } catch (error) {
        console.error('Failed to fetch playlist tracks', error);
      }
    },
    togglePlaylistTracks(playlistId) {
      if (this.openPlaylists.has(playlistId)) {
        this.openPlaylists.delete(playlistId);
      } else {
        this.openPlaylists.add(playlistId);
        this.fetchPlaylistTracks(playlistId); // Fetch tracks when opening the playlist
      }
    },
    isPlaylistOpen(playlistId) {
      return this.openPlaylists.has(playlistId);
    },
    playTrack(trackUri, playlistId, index) {
      this.currentPlaylist = playlistId;
      this.currentTrackIndex = index;
      this.$emit('track-selected', trackUri);
    },
    playNextTrack() {
      if (!this.currentPlaylist || !this.playlistTracks.length) {
        return;
      }
      this.currentTrackIndex = (this.currentTrackIndex + 1) % this.playlistTracks.length;
      const nextTrack = this.playlistTracks[this.currentTrackIndex];
      this.$emit('track-selected', nextTrack.uri);
    },
    playPreviousTrack() {
      if (!this.currentPlaylist || !this.playlistTracks.length) {
        return;
      }
      this.currentTrackIndex = (this.currentTrackIndex - 1 + this.playlistTracks.length) % this.playlistTracks.length;
      const prevTrack = this.playlistTracks[this.currentTrackIndex];
      this.$emit('track-selected', prevTrack.uri);
    }
  },
  async mounted() {
    await this.fetchPlaylists();
  }
};
</script>

<style scoped>
.playlist-comp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playlist-comp input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: box-shadow 0.3s ease;
}

.playlist-comp input:focus {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.playlist-comp ul {
  list-style-type: none;
  padding: 0;
}

.playlist-comp li {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

.playlist-comp li:hover {
  background-color: #e0f7ff;
}

.track-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background-color: #00bfff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #009fcc;
  transform: scale(1.05);
}

.delete-button {
  background-color: #ff4d4d;
  margin-left: 10px;
  padding: 0.3rem 0.6rem;
}

.delete-button:hover {
  background-color: #cc0000;
}

.result-item {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Hover animation */
}

.result-item:hover {
  background-color: #f0f0f0;
}

.playlist-name {
  cursor: pointer;
  transition: color 0.3s ease;
}

.playlist-name:hover {
  color: #007bff;
}

.track-info {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.track-info:hover {
  background-color: #f0f0f0;
  padding: 0.5rem;
  border-radius: 10px;
}
</style>