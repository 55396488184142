import { db } from '@/config/firebaseClient';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';

const state = {
  events: []
};

const getters = {
  events: state => state.events
};

const actions = {
  async fetchEvents({ commit }) {
    const querySnapshot = await getDocs(collection(db, 'events'));
    const events = [];
    querySnapshot.forEach(doc => {
      events.push({ id: doc.id, ...doc.data() });
    });
    commit('setEvents', events);
  },
  async addEvent({ dispatch }, event) {
    await addDoc(collection(db, 'events'), event);
    dispatch('fetchEvents');
  },
  async deleteEvent({ commit }, eventId) {
    await deleteDoc(doc(db, 'events', eventId));
    commit('removeEvent', eventId);
  }
};

const mutations = {
  setEvents(state, events) {
    state.events = events;
  },
  removeEvent(state, eventId) {
    state.events = state.events.filter(event => event.id !== eventId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
