<template>
  <div class="wish-list-page">
    <MenuNavegacion />
    <div class="wish-list-container">
      <h1>Lista de Deseos</h1>
      <div class="wish-item" 
           v-for="wish in wishes" 
           :key="wish.id" 
           :class="{ completed: wish.completed }">
        <p>{{ wish.text }}</p>
        <div class="wish-actions">
          <button v-if="!wish.completed" class="complete-button" @click="handleCompleteWish(wish.id)">Completar</button>
          <button class="delete-button" @click="handleDeleteWish(wish.id)">Borrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    MenuNavegacion
  },
  computed: {
    ...mapGetters('wishes', ['allWishes']),
    wishes() {
      console.log('wishes:', this.allWishes);
      return this.allWishes;
    }
  },
  methods: {
    ...mapActions('wishes', ['completeWish', 'deleteWish']),
    handleCompleteWish(id) {
      console.log('handleCompleteWish:', id);
      this.completeWish(id);
    },
    handleDeleteWish(id) {
      console.log('handleDeleteWish:', id);
      this.deleteWish(id);
    }
  }
};
</script>

<style scoped>
.wish-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 70px 20px;
  background-color: white;
}

.wish-list-container {
  width: 100%;
  max-width: 600px;
}

.wish-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #00bfff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 191, 255, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.wish-item p {
  margin: 0;
  flex-grow: 1;
}

.wish-actions {
  display: flex;
  align-items: center;
}

.complete-button,
.delete-button {
  background-color: #00bfff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.complete-button:hover,
.delete-button:hover {
  background-color: #009acc;
}

.delete-button {
  margin-left: 5px;
}

.completed {
  background-color: #00bfff;
  color: #ffffff;
  border-color: #00bfff;
  box-shadow: none;
  transform: scale(1.05);
}
</style>