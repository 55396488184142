<template>
  <div class="calendar-page">
    <MenuNavegacion />
    <div class="calendar-container">
      <h1>Calendario</h1>
      <div class="calendar-header">
        <button @click="prevMonth">&lt;</button>
        <span>{{ monthName }} {{ currentDate.getFullYear() }}</span>
        <button @click="nextMonth">&gt;</button>
      </div>
      <div class="calendar">
        <div v-for="day in daysInMonth" :key="day.date" class="day" :class="{ 'has-event': day.event, 'today': isToday(day.date) }" @click="openEvent(day)">
          <span>{{ day.date.getDate() }}</span>
        </div>
      </div>
    </div>
    <div v-if="showEventModal" class="modal" @click.self="closeModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <p><strong>Date:</strong> {{ selectedEvent.date }}</p>
        <p><strong>Description:</strong> {{ selectedEvent.description }}</p>
        <p><strong>Rating:</strong> {{ selectedEvent.rating }}</p>
        <button @click="deleteEvent(selectedEvent.id)">Delete Event</button>
      </div>
    </div>
  </div>
</template>

<script>
import MenuNavegacion from '@/components/MenuNavegacion.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CalendarView',
  components: {
    MenuNavegacion,
  },
  data() {
    return {
      showEventModal: false,
      selectedEvent: null,
      currentDate: new Date()
    };
  },
  computed: {
    ...mapGetters('calendar', ['events']),
    monthName() {
      return this.currentDate.toLocaleString('default', { month: 'long' });
    },
    daysInMonth() {
      const days = [];
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      for (let i = 1; i <= daysInMonth; i++) {
        const dayDate = new Date(year, month, i);
        const event = this.events ? this.events.find(event => new Date(event.date).toDateString() === dayDate.toDateString()) : null;
        days.push({ date: dayDate, event });
      }

      return days;
    }
  },
  methods: {
    ...mapActions('calendar', ['fetchEvents', 'deleteEvent']),
    openEvent(day) {
      if (day.event) {
        this.selectedEvent = day.event;
        this.showEventModal = true;
      }
    },
    closeModal() {
      this.showEventModal = false;
      this.selectedEvent = null;
    },
    isToday(date) {
      const today = new Date();
      return date.toDateString() === today.toDateString();
    },
    prevMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
      this.currentDate = new Date(this.currentDate); // Necesario para disparar la reactividad
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.currentDate = new Date(this.currentDate); // Necesario para disparar la reactividad
    },
    async deleteEvent(eventId) {
      await this.$store.dispatch('calendar/deleteEvent', eventId);
      this.closeModal();
    }
  },
  async mounted() {
    await this.fetchEvents();
  },
  watch: {
    currentDate: 'fetchEvents'
  }
};
</script>

<style scoped>
.calendar-page {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden; /* Evitar el scroll horizontal */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.calendar-container {
  flex: 1;
  padding-top: 70px; /* Margen superior para el menú */
  padding: 20px;
  background-color: white;
  color: #000; /* Cambiar el color de la letra del calendario a negro */
  overflow-y: auto;
}

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.calendar-header button {
  background-color: #00bfff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 5px;
}

.calendar-header span {
  font-size: 1.2em;
  color: #000; /* Cambiar el color de la letra del mes y año a negro */
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.day {
  background-color: #444;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.day.has-event {
  background-color: #00bfff;
}

.day.today {
  border: 2px solid #00bfff; /* Cambiar el borde del día actual a azul celeste */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  color: #000;
}
</style>
