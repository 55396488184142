<template>
  <div class="welcome-container">
    <div class="background-image" :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
    <div class="loading-container" v-if="loading">
      <div class="loading-text">{{ loadingPercent }}%</div>
    </div>
    <button class="enter-button" v-if="!loading" @click="enterHome">Soraya</button>
  </div>
</template>

<script>
import backgroundImage from '@/assets/foto1.jpeg';

export default {
  data() {
    return {
      loading: true,
      loadingPercent: 0,
      backgroundImage: backgroundImage
    };
  },
  mounted() {
    this.startLoading();
  },
  methods: {
    startLoading() {
      const interval = setInterval(() => {
        this.loadingPercent += 1;
        if (this.loadingPercent >= 100) {
          clearInterval(interval);
          this.loading = false;
        }
      }, 50); // Ajustar la velocidad de la carga aquí
    },
    enterHome() {
      this.$router.push('/home');
    }
  }
};
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Evitar el scroll */
}

#welcome-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
}

.loading-text {
  animation: fade-in-out 1s infinite;
}

@keyframes fade-in-out {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.enter-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em 2em;
  font-size: 1.5em;
  background-color: #00bfff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enter-button:hover {
  background-color: #009acc;
}
</style>
